import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import eyeIcon from '../../assets/images/icons/eye-off.svg';
import emailIcon from '../../assets/images/icons/mail.svg';
import Error from '../../components/Error';
import FormLoadingButton from '../../components/form-components/FormLoadingButton';
import PasswordField from '../../components/form-components/PasswordField';
import { loginSchema, userLogin } from './utils';
import { useTranslation } from 'react-i18next';
import TextField from 'components/form-components/TextField';
import { CallAPI } from 'actions/General';
import { useDispatch, useSelector } from 'react-redux';
import { AuthReducer } from '../../redux/auth';

const AuthAction = AuthReducer.actions;
const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const [apiError, setApiError] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({
      ...values,
      [field]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setTouched({});
    try {
      await loginSchema.validate(values, { abortEarly: false });

      const email = values.email;
      await userLogin({ email, password: values.password }, setApiError, navigate);
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });

      setErrors(schemaErrors);
    }
    setLoading(false);
  };

  const verifyUser = async () => {
    let result = await CallAPI('VERIFY_USER', null, null, setLoading, setApiError);
    console.log('result ', result);
    if (result.status) {
      dispatch(AuthAction.updateAuthState({ needToVerifyUser: false }));
      navigate('/companies');
    } else {
      dispatch(AuthAction.updateAuthState({ firebaseToken: '', needToVerifyUser: true }));
    }
  };
  useEffect(() => {
    if (authState.firebaseToken && authState.needToVerifyUser === true) verifyUser();
    else if (authState.firebaseToken) {
      navigate('/companies');
    }
  }, [authState]);

  return (
    <div className='auth-form-container'>
      <h3 className='main-heading'>{t('text_welcome_to_midani')}</h3>
      <p className='sub-heading'>{t('text_login_to_midani')}</p>
      {apiError ? <Error msg={apiError} /> : <></>}{' '}
      <TextField
        fieldProps={{ name: 'email', placeholder: '' }}
        displayName='field_email'
        name='email'
        placeholder={t('field_enter_email')}
        icon={emailIcon}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        handleChange={handleChange}
        classes='mb-3'
      />
      <PasswordField
        name='password'
        displayName={t('field_password')}
        placeholder={t('field_enter_password')}
        icon={eyeIcon}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        handleChange={handleChange}
        onEnter={onSubmit}
        classes='mb-3'
      />
      <div className='space-10'></div>
      <FormLoadingButton
        text='button_login'
        variant='green-1'
        loading={loading}
        onClick={onSubmit}
      />
      <div className='space-20'></div>
    </div>
  );
};

export default Login;
