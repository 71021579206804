import * as yup from 'yup';
import * as firebase from '../../config/fbConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { AuthReducer } from '../../redux/auth';
import store from '../../store/store';
const AuthAction = AuthReducer.actions;
const dispatch = store.dispatch;

export const loginSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const userLogin = async (data, setApiError, navigate) => {
  await signInWithEmailAndPassword(firebase.auth, data.email, data.password)
    .then(async (res) => {
      let customClaims = await firebase.auth.currentUser.getIdTokenResult();
      let needToVerifyUser = true;
      if (customClaims.claims['user_role'] && customClaims.claims.user_role) {
        needToVerifyUser = false;
        navigate('/companies');
      }
      dispatch(
        AuthAction.updateAuthState({ firebaseToken: res.user.accessToken, needToVerifyUser }),
      );
    })
    .catch(async (error) => {
      console.log(error);
      if (error.code === 'auth/invalid-credential') {
        setApiError('Email or password is invalid');
        // return { status: 0, message: 'Phone or password is invalid' };
      }
      if (error.code === 'auth/invalid-email') {
        setApiError('Email Format is invalid');
        // return { status: 0, message: 'Phone or password is invalid' };
      }
    });
};
