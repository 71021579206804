import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import arrowRightIcon from 'assets/images/icons/arrow-right.svg';
import midaniLogoArabic from 'assets/images/midani-logo-arabic.svg';
import midaniLogoEnglish from 'assets/images/midani-logo-english.svg';
import selectArrow from 'assets/images/select-arrow.png';
import SelectField from './form-components/SelectField';
import { AuthReducer } from '../redux/auth';
import { isRTL } from 'Language';
import store from 'store/store';

const AuthActions = AuthReducer.actions;
const dispatch = store.dispatch;

const Header = () => {
  const [optionsList, setOptionsList] = useState([]);
  useEffect(() => {
    let dropdownOptions = [
      {
        value: 'logout',
        displayValue: 'select_logout',
        isNested: false,
      },
    ];
    setOptionsList(dropdownOptions);
  }, []);
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleChange = (name, val) => {
    if (val === 'logout') {
      logout();
    }
  };

  const logout = () => {
    dispatch(
      AuthActions.updateAuthState({
        firebaseToken: null,
        needToVerifyUser: false,
      }),
    );
    navigate('/login', { relative: 'path' });
  };

  return (
    <Fragment>
      <div className='header'>
        <div className='header-left'>
          <img src={isRTL() ? midaniLogoArabic : midaniLogoEnglish} alt='logo' />
          <span className='title'>{t('MIDANI')}</span>
        </div>
        <div className='header-center'>
          {auth.breadcrumbs.icon && (
            <Fragment>
              <img src={auth.breadcrumbs.icon} alt='form' />
              <div className='custom-breadcrumb'>
                {auth.breadcrumbs.items.map((item, index) => {
                  return (
                    <div key={index} className='d-flex gap-2 align-items-center'>
                      <img src={arrowRightIcon} />
                      <label>{t(item)}</label>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
        </div>

        <div className='header-right'>
          <div className='user-icon'>
            <span className='text-uppercase'>SA</span>
          </div>
          <div className='user-info'>
            <span className='user-name text-capitalize'>Super Admin</span>
          </div>
          <SelectField
            label={''}
            handleChange={handleChange}
            listValues={optionsList}
            icon={selectArrow}
            position={'end'}
            isAutoClose={true}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
